/*
 * @Author: wangqs 
 * @description 添加银行卡
 * @Date: 2021-07-11 16:24:29 
 * @Last Modified by: wangqs
 * @Last Modified time: 2022-04-23 12:11:15
 */
<template>
  <div class="page-box">
    <div class="title-box box">
      <div class="title-text">
        <img
          :src="require('@/assets/img/bank-logoa.png')"
          alt=""
        >
        添加银行卡
      </div>
    </div>
    <!--  -->
    <div
      class="box"
      style="padding-top: 10px;"
    >
      <!-- 允许输入正整数，调起纯数字键盘 -->
      <van-field
        v-model="checkInfo.bankCardNo"
        required
        type="digit"
        placeholder="请输入银行卡号"
        label="银行卡号"
        input-align="right"
      >
        <template #button>
          <van-uploader
            v-model="cardImgs"
            :after-read="(query)=>{afterRead(query, 'cardImgs')}"
            :max-size="20 * 1024 * 1024"
            :preview-image="false"
            @oversize="onOversize"
            @delete="(e)=>{deleteItem(e, 'cardImgs')}"
          >
            <slot name="default">
              <van-icon
                class="photo-icon"
                name="photograph"
              />
            </slot>
          </van-uploader>
        </template>
      </van-field>
      <van-field
        v-model="checkInfo.bankName"
        required
        label="银行名称"
        placeholder="请输入银行名称"
        input-align="right"
      />
      <!-- 输入手机号，调起手机号键盘 -->
      <van-field
        v-model="checkInfo.bankMobile"
        required
        type="tel"
        placeholder="输入银行预留手机号"
        label="预留手机号"
        input-align="right"
      />
      <!-- 提交 -->
      <van-button
        class="submit-btn"
        color="#51B464"
        round
        type="info"
        @click="nextFun"
      >
        下一步
      </van-button>
    </div>
    <form
      :action="faceData.action || 'https://dsptest.cpcn.com.cn/DSPLivenessWeb/InterfaceVI'"
      name="faceForm"
      style="opacity: 0;position: absolute;left: -2000px;top:0;"
      method="POST"
    >
      <div>
        <input
          type="text"
          name="message"
          placeholder="message"
          :value="faceData.message"
        />
      </div>
      <div>
        <input
          type="text"
          name="signature"
          placeholder="signature"
          :value="faceData.signature"
        />
      </div>
      <div>
        <input
          id="dgtlEnvlp"
          type="text"
          name="dgtlenvlp"
          placeholder="dgtlenvlp"
          :value="faceData.dgtlenvlp"
        />
      </div>
      <div>
        <input
          id="signSN"
          type="text"
          name="signSN"
          placeholder="signSN"
          :value="faceData.signSN"
        />
      </div>
      <div>
        <input
          id="encryptSN"
          type="text"
          name="encryptSN"
          placeholder="encryptSN"
          :value="faceData.encryptSN"
        />
      </div>
    </form>
    <!-- 确认绑卡 码 -->
    <van-overlay
      style="background: rgba(0,0,0,0.3)"
      :show="show"
      @click="show = false"
    >
      <div
        class="wrapper-box"
        @click.stop="show = false"
      >
        <div
          class="block-box"
          @click.stop
        >
          <div class="text-a">
            请填写手机短信验证码
          </div>
          <div class="text-b">
            已发送到{{ checkInfo.mobile }}
          </div>
          <van-field
            v-model="code"
            maxlength="6"
            class="text-c"
            label="验证码"
          />
          <van-button
            class="code-btn"
            color="#51B464"
            round
            type="info"
            @click="bindCardBtn"
          >
            提交
          </van-button>
        </div>
      </div>
    </van-overlay>
  </div>
</template>

<script>
export default {
  name: 'Test',
  components: {
  },
  data () {
    return {
      cardImgs: [],
      cardImgUrl: '',
      isGlobalPage: false,
      checkInfo: {
        bankCardNo: '',
        bankName: '',
        bankMobile: ''
      },
      copyInfo: {
        bankCardNo: '',
        bankName: '',
        bankMobile: ''
      },
      faceData: {},
      show: false,
      requestNo: '',
      code: ''
    }
  },
  computed: {
  },
  created () {
    let orderInfo = JSON.parse(window.localStorage.getItem('orderInfo') || '{}') || {}
    let checkInfo = JSON.parse(window.localStorage.getItem('userCheckInfo') || '{}')
    this.checkInfo = Object.assign(orderInfo, checkInfo)
    this.copyInfo = this.$deepClone(this.checkInfo)
    // 人脸识别回来后带的faceNo
    if (this.$route.query.orderNo && this.$route.query.status == 1) {
      this.checkInfo.faceNo = this.$route.query.orderNo
      this.goNextPage()
      return
    }
  },
  methods: {
    async getUserInfo () {
      let res = await this.$http.orderApi.getOrderInfo({courseNo: this.$route.query.id})
      console.log(res, 'resb')
      this.userInfo = res.msg || {}
    },
    filterParams () {
      let data = {
        bankName: this.checkInfo.bankName,
        cardNo: this.checkInfo.bankCardNo,
        mobile: this.checkInfo.bankMobile,
        type: this.checkInfo.loanType
      }
      if (!data.bankName) {
        this.$toast('请填写银行名称')
        return false
      } else if (!data.cardNo) {
        this.$toast('请填写银行卡号')
        return false
      } else if (!data.mobile) {
        this.$toast('请填写手机号')
        return false
      } else {
        return data
      }
    },
    afterRead (file, key) {
      console.log(file)
      let vm = this;
      var fd = new FormData()
      // fd.append('Content-Type', 'multipart/form-data')
      fd.append('multipartFile', file.file)
      fd.append('dir', 'org')
      vm.$toast.loading({
        message: '加载中',
        forbidClick: true,
        duration: 0
      });
      var xhr = new XMLHttpRequest()
      xhr.open('POST', '/gateway/img/upload', true)
      xhr.onload = function () {
        if (Math.floor(xhr.status / 100) === 2) {
          var res = JSON.parse(xhr.response)
          if (res.code === 200) {
            console.log(res, '上传成功')
            vm.sendUrl(key, res.msg.url || '')
          } else {
            vm.$toast.clear()
            vm.$toast('上传失败，请检查后重新上传')
          }
        } else {
          // clearInterval(vm.timerClock)
          vm.$toast.clear()
          vm.$toast('上传失败')
        }
      }
      xhr.onerror = function () {
        vm.$toast.clear()
        vm.$toast.fail('文件 ' + file.name + ' 上传失败')
      }
      xhr.setRequestHeader('token', JSON.parse(window.localStorage.getItem('userData') || '{}').token)
      xhr.send(fd)
    },
    onOversize (file) {
      console.log(file);
      this.$toast('文件大小不能超过 2M');
    },
    deleteItem (e, key) {
      console.log(e, key)
    },
    async sendUrl (key, url) {
      // bankCardOrc
      if (url) {
        let data = { url }
        this.$toast.loading({
          message: '获取中',
          forbidClick: true
        });
        let res = await this.$http.orderApi.bankCardOrc(data)
        console.log(res, 'res')
        this.$toast.clear()
        if (res.code === 200) {
          this.sendImgInfo = res.msg
          if (res.msg) {
            this.cardImgUrl = url
            this.checkInfo.bankCardNo = res.msg.bankCode || ''
            this.checkInfo.bankName = res.msg.bankName || ''
          }
        }
      } else {
        this.$toast('上传失败')
      }
    },
    async nextFun () {
      if (this.checkInfo.bankMobile.length !== 11) {
        this.$toast('请输入正确手机号')
        return
      }
      if (this.checkInfo.bindCard === 1) {
        let data = this.filterParams()
        if (data) {
          this.$toast.loading({
            message: '加载中',
            forbidClick: true
          });

          let res = await this.$http.userApi.orderBankBind(data)
          if (res.code === 200) {
            this.$toast.clear()
            if (this.checkInfo.bindCard == 1 || this.editBankFilter()) {
              this.show = true
              this.requestNo = res.msg.requestNo || ''
            } else {
              this.goNextPage()
            }
          }
        }
      } else {
        // 人脸识别校验
        if (this.checkInfo.face) {
          if (this.checkInfo.faceNo) {
            let resB = await this.$http.orderApi.orderFaceParams({id: this.checkInfo.faceNo})
            if (resB.code === 200) {
              this.faceData = resB.msg || {}
              this.$nextTick(() => {
                document.faceForm && document.faceForm.submit()
              })
            }
          }
        } else {
          this.goNextPage()
        }
      }
    },
    // 确认绑定
    async bindCardBtn () {
      if (!this.code) {
        this.$toast('请填写验证码')
      } else {
        let data = {
          requestNo: this.requestNo,
          smsCode: this.code
        }
        this.$toast.loading({
          message: '加载中',
          forbidClick: true
        });
        let res = await this.$http.userApi.bankOrderBindConfirm(data)
        if (res.code === 200) {
          this.$toast.success('绑定成功,自动进入下一步')
          setTimeout(() => {
            this.goNextPage()
          }, 1500);
        }
      }
    },
    // 对比修改
    editBankFilter () {
      if (this.copyInfo.bankCardNo !== this.checkInfo.bankCardNo 
      || this.copyInfo.bankName !== this.checkInfo.bankName
      || this.copyInfo.bankMobile !== this.checkInfo.bankMobile) {
        return true;
      }
      return false;
    },
    goNextPage () {
      window.localStorage.setItem('userCheckInfo', JSON.stringify(this.checkInfo))
      window.localStorage.setItem('orderInfo', JSON.stringify(this.checkInfo))
      this.$router.push('/school/authInfo?id=' + this.$route.query.id)
    }
  }
}
</script>

<style lang="scss" scoped>
.page-box {
  .title-box {
    margin-top: 46px;
    line-height:30px;
    font-size: 20px;
    font-weight: bold;
    text-align: center;
    .title-text {
      padding-left: 36px;
      display: inline-block;
      height: 30px;
      position: relative;
      img {
        width: 26px;
        height: 22px;
        position: absolute;
        left: 0;
        top: 4px;
      }
    }
  }
  .submit-btn {
    width: 345px;
    margin: 65px auto 0;
    display: block;
  }
  .photo-icon {
    position: relative;
    padding-right: 8px;
    right: -8px;
  }
}
</style>
